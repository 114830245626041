import { Injectable } from '@angular/core';
import { UploadImageGear } from '../gears/index.js';

@Injectable({ providedIn: 'root' })
export class UploadImageFacade {
  constructor(private uploadImageGear: UploadImageGear) {}
  uploadAvatar(base64: any, userId: any) {
    return this.uploadImageGear.uploadAvatar(base64, userId);
  }
  uploadBookCover(base64: any, bookId: string) {
    return this.uploadImageGear.uploadBookCover(base64, bookId);
  }

  uploadChapterImage(base64: any, name: string, chapterId: string) {
    return this.uploadImageGear.uploadChapterImage(base64, name, chapterId);
  }
}
