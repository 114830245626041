<molec-auth-header [title]="title" [description]="description"> </molec-auth-header>
<molec-forgot-form (enter)="submitEvent.emit(emailFormControl.value)" [emailFormControl]="emailFormControl"></molec-forgot-form>
<forgot-actions [title]="'Gửi Email hướng dẫn'" [active]="emailFormControl.valid" (submitEvent)="submitEvent.emit(emailFormControl.value)"> </forgot-actions>
<div [hidden]="hiddenLoginSuggest">
  <div class="hidden md:block">
    <option-link (click)="closeAllDidalog()" [suggestText]="'Bạn đã nhớ thông tin đăng nhập?'" [title]="'Đăng nhập'" [link]="'/login'"> </option-link>
  </div>
  <div class="block md:hidden">
    <option-link (click)="openFormLoginMobile($event)" [suggestText]="'Bạn đã nhớ thông tin đăng nhập?'" [title]="'Đăng nhập'" [link]="null"> </option-link>
  </div>
  <option-link (click)="closeAllDidalog()" [suggestText]="'Chưa có tài khoản?'" [title]="'Đăng ký'" [link]="'/register'"></option-link>
</div>
