import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';

import { DialogRef } from '@ngneat/dialog';

interface data {
  firstTotalLuckyMoney: number;
  currentTotalLuckyMoney: number;
  noti: any;
}

@Component({
  standalone: true,
  selector: 'organ-popup-lucky-money',
  imports: [CommonModule],
  templateUrl: './popup-lucky-money.organ.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host .shadoww {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
      }
    `,
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupLuckyMoneyOrgan implements OnInit {
  data = this.dialogRef.data;
  constructor(public dialogRef: DialogRef<any>) {}

  ngOnInit(): void {}

  accept() {
    this.dialogRef.close(this.data.noti);
  }
}
