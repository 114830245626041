import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
import { OptionLinkAtom } from '../../atoms/option-link/option-link.atom.js';
import { AuthHeaderMolec } from '../../molecs/auth-header/auth-header.molec.js';
import { ForgotActionsMolec } from '../../molecs/forgot-actions/forgot-actions.molec.js';

@Component({
  standalone: true,
  selector: 'organ-forgot-password-sent',
  imports: [CommonModule, AuthHeaderMolec, ForgotActionsMolec, OptionLinkAtom],
  templateUrl: './forgot-password-sent.organ.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordSentOrgan implements OnInit {
  title = 'Kiểm tra Email của bạn';
  @Input() description = '';
  @Input() email = '';
  @Output() submitEvent = new EventEmitter();
  @Output() resend = new EventEmitter();
  @Input() hiddenLoginSuggest = false;
  constructor(private dialogService: DialogService, private router: Router) {}

  ngOnInit(): void {
    this.description = `Chúng tôi đã gửi đường dẫn đặt lại mật khẩu của bạn tới địa chỉ ${this.email}, hãy chắc chắn bạn đã kiểm tra thư mục spam.`;
  }

  openFormLoginMobile(event: any): void {
    this.dialogService.closeAll();
    this.router.navigateByUrl('/login');
  }
}
