<atom-example-dialog class="h-full">
  <section [formGroup]="form" class=" relative h-full">
    <atom-popup-cover-header [title]="'Đổi mật khẩu'"></atom-popup-cover-header>
    <p class="p-4">Nếu bạn đăng nhập bằng mạng xã hội, vui lòng thiết lập email sau đó sử dụng tính năng quên mật khẩu để đổi mật khẩu mới</p>
    <div class="p-4">
      <input-form [type]="'password'" [faIcon]="icons.password" (enter)="new.inputForm.nativeElement.focus()" [inputControl]="$any(form.get('oldpassword'))"
        [placeholder]="'Mật khẩu cũ'">
      </input-form>
      <input-form #new [type]="'password'" [faIcon]="icons.password" (enter)="confirm.inputForm.nativeElement.focus()"
        [inputControl]="$any(form.get('password'))" [placeholder]="'Mật khẩu mới'">
      </input-form>
      <input-form #confirm [type]="'password'" [faIcon]="icons.password" (enter)="finish()" [inputControl]="$any(form.get('confirmpassword'))"
        [placeholder]="'Nhập lại mật khẩu mới'">
      </input-form>
      <normal-login-btn [name]="'Xác nhận'" [active]="true" (btnClicked)="finish()"></normal-login-btn>
    </div>
  </section>
</atom-example-dialog>