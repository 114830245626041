import { Injectable } from '@angular/core';
import { UploadImageApi } from '../apis/index.js';
import { numberAndTextNid } from '@awread/kernel/tools';
@Injectable({ providedIn: 'root' })
export class UploadImageGear {
  constructor(private uploadImageApi: UploadImageApi) {}

  uploadAvatar(payload: any, userId: string) {
    return this.uploadImageApi.up(payload, 'avatar', userId, { userId });
  }

  uploadBookCover(payload: any, bookId: string) {
    return this.uploadImageApi.up(payload, 'book-cover', bookId, { bookId });
  }

  uploadChapterImage(payload: any, name: string, chapterId: string) {
    return this.uploadImageApi.up(payload, 'chapter-image', name, { chapterId });
  }
}
