<molec-auth-header [title]="title" [description]="description"> </molec-auth-header>
<div class="hidden md:block">
  <forgot-actions [title]="'Đóng'" [active]="true" (submitEvent)="submitEvent.emit($event)"></forgot-actions>
</div>
<div class="block md:hidden">
  <forgot-actions [title]="'Đóng'" [active]="true" (submitEvent)="openFormLoginMobile($event)"></forgot-actions>
</div>
<option-link [suggestText]="'Bạn chưa nhận được Email? Chờ 1 phút sau đó bấm gửi lại nha!!!'" [title]="'Gửi lại'" (btnClicked)="resend.emit()"></option-link>
<div [hidden]="hiddenLoginSuggest">
  <div class="hidden md:block">
    <option-link [suggestText]="'Bạn đã nhớ thông tin đăng nhập?'" [title]="'Đăng nhập'" [link]="'/login'"></option-link>
  </div>
  <div class="block md:hidden">
    <option-link (click)="openFormLoginMobile($event)" [suggestText]="'Bạn đã nhớ thông tin đăng nhập?'" [title]="'Đăng nhập'" [link]="null"></option-link>
  </div>
</div>
