import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthValidator, SnackbarService } from '@awread/global/packages';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { DialogRef } from '@ngneat/dialog';
import { ExampleDialogAtom } from '../../atoms/example-dialog/example-dialog.atom.js';
import { InputFormAtom } from '../../atoms/input-form/input-form.atom.js';
import { NormalLoginBtn } from '../../atoms/normal-login-btn/normal-login-btn.atom.js';
import { PopupCoverHeaderAtom } from '../../atoms/popup-cover-header/popup-cover-header.atom.js';
@Component({
  standalone: true,
  selector: 'organ-change-password-popup',
  imports: [CommonModule, ReactiveFormsModule, ExampleDialogAtom, PopupCoverHeaderAtom, InputFormAtom, NormalLoginBtn],
  templateUrl: './change-password-popup.organ.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordPopupOrgan implements OnInit {
  icons = {
    password: faLock,
  };
  @Input() form: FormGroup = this.fb.group(
    {
      oldpassword: ['', { validators: [Validators.required, Validators.minLength(4)] }],
      password: ['', { validators: [Validators.required, Validators.minLength(4)] }],
      confirmpassword: ['', { validators: [Validators.required, Validators.minLength(4)] }],
    },
    { validator: this.authValidator.passwordMatchValidator }
  );
  constructor(private fb: FormBuilder, private authValidator: AuthValidator, public dialogRef: DialogRef<any>, private snackbarService: SnackbarService) {}

  ngOnInit(): void {}

  finish() {
    console.log('form', this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      if (this.form?.errors?.['missmatch']) {
        this.snackbarService.showWarning('Mật khẩu mới không khớp');
      } else {
        this.snackbarService.showWarning('Vui lòng nhập đầy đủ thông tin');
      }
    }
  }
}
