import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UploadImageFacade } from '@awread/feature/upload-image';
import { SnackbarService } from '@awread/global/packages';
import { DialogRef } from '@ngneat/dialog';
import { ExampleDialogAtom } from '../../atoms/example-dialog/example-dialog.atom.js';
import { PopupCoverHeaderAtom } from '../../atoms/popup-cover-header/popup-cover-header.atom.js';
import { PopupCoverImageMolec } from '../../molecs/popup-cover-image/popup-cover-image.molec.js';
@Component({
  standalone: true,
  selector: 'organ-popup-change-cover',
  imports: [ExampleDialogAtom, PopupCoverHeaderAtom, PopupCoverImageMolec],
  templateUrl: './popup-change-cover.organ.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupChangeCoverOrgan implements OnInit {
  @Input() title = 'Chọn ảnh bìa sách';
  controlName = new FormControl('');
  status = 'pending';
  percentLoading = '10%';
  aspectRatio = 1;
  roundCropper = false;
  constructor(
    public dialogRef: DialogRef<any>,
    private cd: ChangeDetectorRef,
    private uploadImageFacade: UploadImageFacade,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    if (this.dialogRef.data.mode == 'avatar') {
      this.title = 'Chọn ảnh đại diện';
      this.roundCropper = true;
    } else {
      this.aspectRatio = 288 / 384;
    }
  }

  eventChooseImage(event: any) {
    this.status = 'reposition';
  }

  submitEvent(croppedImage: string) {
    // console.log('image', croppedImage); //base64
    this.status = 'loading';
    if (croppedImage) {
      let upload$;
      if (this.dialogRef.data.mode == 'avatar') {
        upload$ = this.uploadImageFacade.uploadAvatar(croppedImage, this.dialogRef.data.id);
      } else {
        upload$ = this.uploadImageFacade.uploadBookCover(croppedImage, this.dialogRef.data.id);
      }
      //save image
      upload$.subscribe({
        complete: () => {
          console.log('dang anh hoan tat');
        },
        error: (error) => {
          console.warn('upload image failed', error);
          this.snackbarService.showError('Đăng ảnh thất bại, vui lòng chụp màn hình và gửi hỗ trợ');
          this.status = 'reposition';
          this.cd.detectChanges();
        },
        next: (event) => {
          // console.log('event', event);
          if (event.type === HttpEventType.DownloadProgress) {
            // console.log("download progress");
            this.updatePercent(Math.round((event.loaded * 100) / (event.total || 1)));
          }
          if (event.type === HttpEventType.Response) {
            // console.log("download completed");
            this.updatePercent(80);
          }
          if (event instanceof HttpResponse) {
            if (event.ok) {
              setTimeout(() => {
                this.updatePercent(90);
                setTimeout(() => {
                  this.updatePercent(100);
                  setTimeout(() => {
                    this.dialogRef.close({ success: event.ok });
                  }, 500);
                }, 500);
              }, 500);
            } else {
              this.snackbarService.showError('Đăng ảnh thất bại, vui lòng chụp màn hình và gửi hỗ trợ');
              this.status = 'reposition';
              this.cd.detectChanges();
            }
          }
        },
      });
    } else {
      this.snackbarService.showWarning('Lỗi tối ưu ảnh!');
      this.dialogRef.close();
    }
  }

  updatePercent(percent: number) {
    this.percentLoading = `${percent}%`;
    this.cd.detectChanges();
  }
}
