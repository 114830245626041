import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RdTabMobileMolec, TabLeftDesktopMolec } from '../../molecs/index.js';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'organ-dynamic-tab-layout',
  standalone: true,
  imports: [RouterModule, CommonModule, RdTabMobileMolec, TabLeftDesktopMolec],
  templateUrl: './dynamic-tab-layout.organ.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTabLayoutOrgan {
  @Input() options = {
    desktopStyle: 1,
    mobileStyle: 1,
  };
  @Input() tabs: Record<string, any> = {};
  isMobile = (globalThis as any)['isMobile'];



}
