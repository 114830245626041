import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
import { OptionLinkAtom } from '../../atoms/option-link/option-link.atom.js';
import { AuthHeaderMolec } from '../../molecs/auth-header/auth-header.molec.js';
import { ForgotActionsMolec } from '../../molecs/forgot-actions/forgot-actions.molec.js';
import { ForgotFormMolec } from '../../molecs/forgot-form/forgot-form.molec.js';
@Component({
  standalone: true,
  selector: 'organ-forgot-password',
  imports: [CommonModule, ReactiveFormsModule, AuthHeaderMolec, ForgotActionsMolec, ForgotFormMolec, OptionLinkAtom],
  templateUrl: './forgot-password.organ.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordOrgan implements OnInit {
  @Input() emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  @Input() title = 'Quên mật khẩu?';
  @Input() description = 'Vui lòng nhập địa chỉ email đã dùng của bạn khi tạo tài khoản, chúng tôi sẽ gửi hướng dẫn đặt lại mật khẩu cho bạn.';
  @Output() submitEvent = new EventEmitter();
  @Input() hiddenLoginSuggest = false;
  constructor(private dialogService: DialogService, private router: Router) {}

  ngOnInit() {}

  closeAllDidalog() {
    this.dialogService.closeAll();
  }

  openFormLoginMobile(event: any): void {
    this.dialogService.closeAll();
    this.router.navigateByUrl('/login');
  }
}
