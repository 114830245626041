<section class="bg-white relative h-full">
  <atom-popup-cover-header [title]="title"></atom-popup-cover-header>
  <molec-popup-cover-image
    style="height: calc(100% - 4rem)"
    [roundCropper]="roundCropper"
    [aspectRatio]="aspectRatio"
    [status]="status"
    [percentLoading]="percentLoading"
    [controlName]="controlName"
    (eventChooseImage)="eventChooseImage($event)"
    (submitEvent)="submitEvent($event)"
  >
  </molec-popup-cover-image>
</section>
